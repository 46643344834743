import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import Zoom from "react-reveal/Zoom";
import withReveal from "react-reveal/withReveal";
import placeholder from "../../static/placeholder.jpg";

const ProjectCard = withReveal(
  styled.div`
    display: inline-block;
    margin: 10px;
    width: 300px;
    border: solid 1px var(--light-gray);
    background: white;
    box-shadow: var(--shadow);
    border-radius: 5px;
    overflow: hidden;
    transition: all 1s ease;

    @media (max-width: 890px) {
      width: 80vw;
    }

    @media (min-width: 890px) and (max-width: 1026px) {
      width: 40vw;
    }
  `,
  <Zoom />
);

const CropBox = styled.div`
  overflow: hidden;
  width: 100%;
  display: inline-block;
`;

const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;

  transform: scale(1);
  transition: all 1s ease;

  :hover {
    transform: scale(1.2);
    transition: all 1s ease;
  }
`;

const Details = styled.div`
  height: 150px;
  overflow: hidden;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;

  p.keywords {
    font-size: 0.7rem;
  }

  p.excerpt {
    margin: 0;
    font-size: 1rem;
  }
`;

// Gatsby automagically maps the query data into data, so that it can be used here.
export default (props) => {
  return (
    <ProjectCard>
      <div>
        <a
          href={
            props.node.frontmatter.link
              ? props.node.frontmatter.link
              : props.node.fields.slug
          }
          alt={props.node.frontmatter.title}
          target={props.node.frontmatter.link ? "_blank" : "_self"}
        >
          <CropBox>
            {props.node.frontmatter.featuredImage ? (
              <BgImg
                sizes={
                  props.node.frontmatter.featuredImage.childImageSharp.sizes
                }
              />
            ) : (
              <img src={placeholder} alt="Placeholder" />
            )}
          </CropBox>
        </a>
        <Details>
          <a
            href={
              props.node.frontmatter.link
                ? props.node.frontmatter.link
                : props.node.fields.slug
            }
            alt={props.node.frontmatter.title}
            target={props.node.frontmatter.link ? "_blank" : "_self"}
          >
            <h3>{props.node.frontmatter.title}</h3>
          </a>
          <p className="excerpt">{props.node.frontmatter.excerpt}</p>
          <p className="keywords">{props.node.frontmatter.keywords}</p>
        </Details>
      </div>
    </ProjectCard>
  );
};
