// TODO: Add react query
import React from "react";
import styled from "styled-components";
import Title from "./sectionTitle";
import { StaticQuery, graphql } from "gatsby";
import SectionContent from "./sectionContent";
import ProjectCard from "../projectCard";

const SectionBody = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
  padding: 0;

  h3 {
    width: 100%;
    flex-base: 100%;
    text-align: center;
  }
`;

// Gatsby automagically maps the query data into data, so that it can be used here.
export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: {
            fileAbsolutePath: { regex: "/(projects)/" }
            frontmatter: { status: { eq: "Published" } }
          }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                keywords
                excerpt
                link
                date(formatString: "MMMM Do, YYYY")
                featuredImage {
                  id
                  childImageSharp {
                    id
                    sizes(maxWidth: 600) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
              excerpt
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="section">
        <SectionContent>
          <Title id="projects">Projects</Title>
          <SectionBody>
            {data.allMarkdownRemark &&
              data.allMarkdownRemark.edges.map(({ node }, index) => {
                return <ProjectCard key={node.id} node={node} />;
              })}
          </SectionBody>
        </SectionContent>
      </div>
    )}
  />
);
