import React from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import FrontImage from "../components/frontImage";
// import BlogSection from "../components/sections/blogSection";
import AboutSection from "../components/sections/aboutSection";
import ProjectsSection from "../components/sections/projectsSection";
import CvSection from "../components/sections/cvSection";
import { Link } from "gatsby";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Head title="Home" />
      <Link to="/#projects">
        <FrontImage />
      </Link>
      {/* <BlogSection /> */}
      <ProjectsSection />
      <AboutSection />
      <CvSection />
    </Layout>
  );
};

export default IndexPage;
