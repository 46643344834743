import styled from "styled-components";
import featuredImage from "../../static/indexHeader.jpg";

const FeaturedImage = styled.div`
  background-image: url(${featuredImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0px;
  width: 100vw;
  height: calc(100vh - 5rem);

  @media (max-width: 700px) {
    /* Show laptop instead of the (same) logo on the screen in mobile view. */
    background-position: 12% 75%;
  }
`;

export default FeaturedImage;
