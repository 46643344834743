import React from "react";
import styled from "styled-components";
import withReveal from "react-reveal/withReveal";
import Zoom from "react-reveal/Zoom";

const Title = withReveal(
  styled.h1`
    display: inline-block;
    width: 100%;
    text-align: center;
    font-weight: 200;
    margin-bottom: 32px;
  `,
  <Zoom collapse />
);

export default Title;
