import React from "react";
import styled from "styled-components";
import me from "../../../static/me.jpg";
import Fade from "react-reveal/Fade";
import Title from "./sectionTitle";

const TextSummary = styled.div`
  flex-basis: 70%;
  padding-right: 2rem;

  @media (max-width: 800px) {
    flex-basis: 100%;
  }
`;

const Photo = styled.div`
  flex-basis: 30%;

  @media (max-width: 800px) {
    flex-basis: 60%;
    margin: auto;
  }
`;

const SectionContent = styled.div``;

const SectionBody = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: top;
`;

const AboutSection = () => {
  return (
    <div className="section bg-dark">
      <SectionContent>
        <Title id="about">About</Title>
        <SectionBody>
          <TextSummary>
            <p>
              After graduating Industrial Design Engineering (MSc, Delft), I’ve
              gradually shifted towards back to web- and software development
              and expanded my skills in that field.
            </p>
            <p>
              I have a strong work ethic, good communicative skills and always
              try to keep learning by creating great new products together with
              others.
            </p>
            <p>
              In my spare time I re-charge by making and listening to music,
              cycling or driving a motorcycle or trying out new coding tools.
            </p>
          </TextSummary>
          <Photo>
            <Fade right>
              <img src={me} alt="Igor Schouten" className="rounded" />
            </Fade>
          </Photo>
        </SectionBody>
      </SectionContent>
    </div>
  );
};

export default AboutSection;
