import React from "react";
import styled from "styled-components";
import CV from "../../../static/igorschouten-cv.pdf";

import { MdPictureAsPdf } from "react-icons/md";
import Zoom from "react-reveal/Zoom";
import SectionContent from "./sectionContent";
import Title from "./sectionTitle";

const SectionBody = styled.div`
  a,
  a:active {
    text-align: center;
    color: white;
  }

  .large-icon {
    font-size: 50px;
    margin-top: 32px;
    transform: scale(1);
    transition: all 500ms ease;

    :hover {
      transform: scale(1.3);
    }
  }
`;

const ContactSection = () => {
  return (
    <div className="section bg-accent">
      <SectionContent>
        <Title id="cv">Curriculum Vitae</Title>
        <SectionBody>
          <a href={CV} download>
            <Zoom>
              <MdPictureAsPdf className="large-icon" />
            </Zoom>
            <p>Download my CV here</p>
          </a>
        </SectionBody>
      </SectionContent>
    </div>
  );
};

export default ContactSection;
